function type(value) {
    return typeof value === 'string';
}

function min(value, min) {
    return value.length >= min;
}

function max(value, max) {
    return value.length <= max;
}

function length(value, length) {
    return value.length === length;
}

function equal(value, valueEqual) {
    return value === valueEqual;
}

function testRegexp(value, regexp) {
    return regexp.test(value);
}

export {
    type,
    min,
    max,
    length,
    equal,
    testRegexp,
};
