import {Toasts} from '@scripts/helpers';

function bootToasts(componentStore) {
    const component = componentStore.getById('toasts');

    Toasts
        .component(component)
        .preset(Toasts.PRESETS.DATA_SAVED, {
            message: 'Данные успешно изменены',
            icon: 'check',
        })
        .preset(Toasts.PRESETS.WARNING, {
            message: 'Извините, произошла ошибка. Попробуйте позже',
            icon: 'info',
            style: 'warning',
        });
}

export {bootToasts};
