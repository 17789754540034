import {BaseSelect} from './BaseSelect';

class SelectMultiple extends BaseSelect {
    #selectedNodes = new Set();
    #selectedValues = [];

    constructor(node, id) {
        super(node, id);

        const rawSelected = node.dataset.selected;

        if (rawSelected) {
            const selected = new Set(rawSelected.split(','));
            const options = this.optionNodes.filter(option => selected.has(option.dataset.value));

            this.select(options);
        }
    }

    get rawValue() {
        return this.#selectedValues;
    }

    select(selectable) {
        if (!Array.isArray(selectable)) {
            selectable = [selectable];
        }

        for (const selectedOption of selectable) {
            if (this.#selectedNodes.has(selectedOption)) {
                selectedOption.removeAttribute('data-selected');
                this.#selectedNodes.delete(selectedOption);

                if (this.#selectedNodes.size === 0) {
                    this.node.setAttribute('data-empty', '');
                }
            } else {
                selectedOption.setAttribute('data-selected', '');
                this.#selectedNodes.add(selectedOption);

                if (this.#selectedNodes.size === 1) {
                    this.node.removeAttribute('data-empty');
                }
            }
        }

        const selectedValues = [];
        const titleElements = [];

        for (const optionNode of this.optionNodes) {
            if (!this.#selectedNodes.has(optionNode)) continue;

            selectedValues.push(optionNode.dataset.value);
            titleElements.push(optionNode.querySelector('[data-title]').textContent);
        }

        this.#selectedValues = selectedValues;

        this.title(titleElements.join('; '));
        this.emit('input', selectedValues, this.#selectedNodes);
    }
}

export {SelectMultiple};
