import {EventEmitter} from '@scripts/helpers';

class Validator {
    #eventEmitter;
    #required = false;
    #validateCallback;
    #message = null;
    #formField;
    #events = ['input'];
    #getValue = null;
    #withoutMessage = false;
    #isValid;

    constructor() {
        this.#eventEmitter = new EventEmitter();
    }

    get isValid() {
        return this.#isValid;
    }

    required() {
        this.#required = true;

        return this;
    }

    validateCallback(callback) {
        this.#validateCallback = callback;

        return this;
    }

    message(message) {
        this.#message = message;

        return this;
    }

    rule(rule) {
        this.validateCallback(rule.validate.bind(rule));
        this.#message = rule.message;

        return this;
    }

    formField(formField) {
        this.#formField = formField;

        return this;
    }

    events(events) {
        this.#events = events;

        return this;
    }

    addEvent(event) {
        this.#events.push(event);

        return this;
    }

    getValue(getValue) {
        this.#getValue = getValue;

        return this;
    }

    withoutMessage() {
        this.#withoutMessage = true;

        return this;
    }

    valid(isValid = true) {
        this.#isValid = isValid;

        return this;
    }

    #setFormFieldMessage() {
        if (this.#withoutMessage) return;

        const message = this.#isValid ? null : this.#message;

        this.#formField.error(message);
    }

    // Убрал приватное своейство, чтобы вызывать при валидации подтверждения пароля
    validate(...args) {
        this.#eventEmitter.emit('input');
        const value = typeof this.#getValue === 'function' ? this.#getValue(...args) : this.#getValue;
        const valid = !this.#required && !value || this.#validateCallback(value);

        if (this.#isValid === valid) return;

        this.#isValid = valid;

        this.#setFormFieldMessage();
        valid && this.#eventEmitter.emit('valid');
        !valid && this.#eventEmitter.emit('invalid');
    }

    compile() {
        for (const event of this.#events) {
            this.#formField.addEventListener(event, this.validate.bind(this));
        }

        return this;
    }

    onValid(listener) {
        this.#eventEmitter.on('valid', listener);

        return this;
    }

    onInvalid(listener) {
        this.#eventEmitter.on('invalid', listener);

        return this;
    }

    onInput(listener) {
        this.#eventEmitter.on('input', listener);

        return this;
    }
}


export {Validator};
