import flatpickr from 'flatpickr';
import {Russian} from 'flatpickr/dist/l10n/ru';

import {FormField} from './FormField';
import {getUnixTimestampFromDate} from '@scripts/helpers';

class Datepicker extends FormField {
    #flatpickr;
    #mobileInput;

    constructor(node, id) {
        super(node, id);

        this.#flatpickr = flatpickr(this.field, {
            enableTime: true,
            altInput: true,
            altFormat: 'd.m.Y в H:i',
            dateFormat: 'Y-m-d',
            time_24hr: true,
            locale: Russian,
            clickOpens: false,
            onOpen: () => node.classList.add('datepicker--opened'),
            onClose: () => node.classList.remove('datepicker--opened'),
        });

        this.#flatpickr.altInput.classList.remove('form-control', 'input');
        this.#mobileInput = node.getElementsByClassName('flatpickr-mobile')[0] ?? null;

        if (node.dataset.timestamp) {
            this.setTimestamp(parseInt(node.dataset.timestamp));
            node.removeAttribute('data-value');
        }

        this.#replaceToggle();
        node.addEventListener('click', () => this.#flatpickr.toggle());
    }

    #replaceToggle() {
        const toggle = this.#flatpickr.toggle.bind(this.#flatpickr);

        this.#flatpickr.toggle = () => {
            if (this.node.getAttribute('disabled') !== null) return;

            if (this.#mobileInput) {
                this.#mobileInput.showPicker();
            } else {
                toggle();
            }
        };
    }

    setOption(option, value) {
        this.#flatpickr.set(option, value);

        return this;
    }

    setOptions(options) {
        for(const [option, value] of Object.entries(options)) {
            this.setOption(option, value);
        }

        return this;
    }

    setValue(value) {
        this.#flatpickr.setDate(value, true);

        return this;
    }

    setTimestamp(timestamp) {
        return this.setValue(new Date(timestamp * 1000));
    }

    get rawValue() {
        const date = this.#flatpickr.selectedDates[0] ?? null;

        if (date) {
            return getUnixTimestampFromDate(date);
        }

        return date;
    }

    get flatpickr() {
        return this.#flatpickr;
    }
}

export {Datepicker};
