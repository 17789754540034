import {Group} from './Group';

class RadioGroup extends Group {
    getValue() {
        return this.components.find(component => component.isChecked)?.getValue() ?? null;
    }

    setValue(value) {
        for (const component of this.components) {
            if (component.getValue() !== value) continue;

            component.check();

            break;
        }

        return this;
    }

    disable() {
        for (const component of this.components) {
            component.disable();
        }

        return this;
    }

    enable() {
        for (const component of this.components) {
            component.enable();
        }

        return this;
    }
}

export {RadioGroup};
