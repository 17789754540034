// TODO REFACTORING THIS FILE LATER

async function checkNotifications(userLayoutNotificationsIcon, userLayoutNotificationsEmptyIcon) {
    const response = await fetch('/api/alert/has');
    if (!response.ok) {
        return;
    }
    const hasNotifications = await response.json();



    if (hasNotifications) {
        userLayoutNotificationsIcon.classList.remove('hidden');
    }

    if (hasNotifications) {
        userLayoutNotificationsIcon.show();
        userLayoutNotificationsEmptyIcon.hide();
    } else {
        userLayoutNotificationsIcon.hide();
        userLayoutNotificationsEmptyIcon.show();
    }
}

function maybeToggleVisibleNotifications(userLayoutNotifications) {
    return event => {
        if (event.target.closest('.notifications')) return;

        userLayoutNotifications.toggleVisible();
    };
}

function maybeCloseNotifications(userLayoutNotifications) {
    document.addEventListener('click', event => {
        if (event.target.closest('.notifications, .user')) return;

        userLayoutNotifications.invisible();
    });
}

function renderNotification(text) {
    const notificationNode = document.createElement('div');

    notificationNode.className = 'notifications__notification';
    notificationNode.innerHTML = text;

    return notificationNode;
}

function maybeLoadNotifications(userLayoutNotificationsIcon, userLayoutNotificationsEmptyIcon, userLayoutNotifications) {
    let isLoading = false;
    let notifications;

    return async () => {
        // TODO remove css class
        if (!userLayoutNotifications.classList.contains('invisible') && !isLoading && !notifications) {
            isLoading = true;

            const notificationsResponse = await fetch('/api/alert/list');
            const notificationsIds = [];

            notifications = await notificationsResponse.json();

            if (notifications.length === 0) {
                userLayoutNotifications.callProperty('appendChild', renderNotification('Уведомлений нет'));

                return;
            }

            for (const notification of notifications) {
                userLayoutNotifications.callProperty('appendChild', renderNotification(notification.text));

                if (!notification.read) {
                    notificationsIds.push(notification.id);
                }
            }

            if (notificationsIds.length > 0) {
                await fetch('/api/alert/read', {
                    method: 'post',
                    headers: {
                        'content-type': 'application/json',
                        'accept': 'application/json',
                    },
                    body: JSON.stringify({
                        ids: notificationsIds,
                    }),
                });

                userLayoutNotificationsIcon.hide();
                userLayoutNotificationsEmptyIcon.show();
            }
        }
    }
}

function bootNotifications(componentStore) {
    const {
        userLayoutNotificationsIcon,
        userLayoutUser,
        userLayoutNotifications,
        userLayoutNotificationsEmptyIcon,
    } = componentStore.all();

    if (!userLayoutUser) return;

    checkNotifications(userLayoutNotificationsIcon, userLayoutNotificationsEmptyIcon);

    userLayoutUser.on('click', maybeToggleVisibleNotifications(userLayoutNotifications));
    userLayoutUser.on('click', maybeLoadNotifications(userLayoutNotificationsIcon, userLayoutNotificationsEmptyIcon, userLayoutNotifications));

    maybeCloseNotifications(userLayoutNotifications);
}

export {bootNotifications};
