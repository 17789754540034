import {Group} from './Group';

class CheckboxGroup extends Group {
    getValue() {
        return this.components.reduce((acc, component) => {
            if(component.getValue()) {
                acc.push(component.getProperty('value'));
            }
            return acc;
        }, []);
    }

    // TODO создать реализацию метода setValue
    // setValue(value) {
    //     for (const component of this.components) {
    //         if (component.getValue() !== value) continue;
    //
    //         component.check();
    //
    //         break;
    //     }
    //
    //     return this;
    // }

    disable() {
        for (const component of this.components) {
            component.disable();
        }

        return this;
    }

    enable() {
        for (const component of this.components) {
            component.enable();
        }

        return this;
    }
}

export {CheckboxGroup};
