import {bootAuthLayout} from '@scripts/boot';
import {Timer, Toasts, onEnter, sendMetrics} from '@scripts/helpers';
import {StringRule, PassRule} from '@scripts/validation/rules';
import {phonePreset, emailPreset, passPreset} from '@scripts/validation/presets';
import {Validator} from '@scripts/validation/Validator';
import {Gate} from '@scripts/validation/Gate';
import {Auth} from '@scripts/http/api';

const authApi = new Auth();

function initMetrics(componentStore) {
    if (!METRICS_ENABLE) return;

    const {
        sendSmsCodeButton,
        enterEmailButton,
    } = componentStore.all();

    sendSmsCodeButton.on('click', () => {
        sendMetrics({
            type: 'reachGoal',
            eventID: 'entr_get_code',
            eventCategory: 'button',
        });
    });

    enterEmailButton.on('click', () => {
        sendMetrics({
            type: 'reachGoal',
            eventID: 'entr_email',
            eventCategory: 'button',
            only: ['vk']
        });
    });
}

function initValidation(componentStore) {
    const {
        phoneInput,
        sendSmsCodeButton,
        emailInput,
        inputPassword,
        enterEmailButton,
        emailInputRestore,
        restorePass
    } = componentStore.all();

    new Validator()
        .required()
        .rule(phonePreset)
        .formField(phoneInput)
        .addEvent('blur')
        .getValue(phoneInput.inputmask)
        .withoutMessage()
        .compile()
        .onValid(() => sendSmsCodeButton.enable())
        .onInvalid(() => sendSmsCodeButton.disable());

    const emailRestoreValidator = new Validator()
        .required()
        .rule(emailPreset)
        .formField(emailInputRestore)
        .addEvent('blur')
        .getValue(() => emailInputRestore.getValue())
        .compile()
        .onValid(() => restorePass.enable())
        .onInvalid(() => restorePass.disable());

    const emailValidator = new Validator()
        .required()
        .rule(emailPreset)
        .formField(emailInput)
        .getValue(() => emailInput.getValue())
        .addEvent('blur')
        .compile();

    const passwordValidator = new Validator()
        .required()
        .rule(passPreset)
        .formField(inputPassword)
        .getValue(() => inputPassword.getValue())
        .addEvent('blur')
        .compile();

    return {
        gate: new Gate()
            .addValidator('email', emailValidator)
            .addValidator('password', passwordValidator)
            .onValid(() => enterEmailButton.enable())
            .onInvalid(() => enterEmailButton.disable()),
        emailRestoreValidator,
    };

}

function sendSmsCode(componentStore, timer) {
    const {
        phoneInput,
        phoneSpan,
        sendSmsCodeButton,
        codeInput,
        resendSmsCodeLink,
        timerSpan,
        enterButton,
        phoneCard,
        codeCard,
    } = componentStore.all();

    let oldPhone = null;

    return async () => {
        const newPhone = phoneInput.getValue();
        const isPhoneChanged = oldPhone !== newPhone;
        const shouldSendSmsCode = isPhoneChanged || !timer.isProcess;

        if (isPhoneChanged) {
            oldPhone = newPhone;
            phoneSpan.textContent = phoneInput.maskedValue;
        }

        if (shouldSendSmsCode) {
            phoneInput.disable();
            sendSmsCodeButton.disable();

            const response = await authApi.getLoginAuthSmsCode(oldPhone);

            phoneInput.enable();
            sendSmsCodeButton.enable();

            if (!response.ok) {
                if (response.statusCode === 403) {
                    phoneInput.error(response.body.errors.user);
                } else if (response.statusCode === 422) {
                    phoneInput.error(response.body.errors.phone[0]);
                } else if (response.statusCode === 429) {
                    Toasts.addFromPreset(Toasts.PRESETS.WARNING, {
                        message: 'Слишком много попыток. Попробуйте позже',
                    });
                } else {
                    Toasts.addFromPreset(Toasts.PRESETS.WARNING);
                }

                return;
            }

            timer.stop();
        }

        codeInput.setValue('');
        timer.start(SMS_SEND_TIMEOUT_SECONDS);
        resendSmsCodeLink.hide();
        enterButton.hide();
        timerSpan.show();
        phoneCard.invisible();
        codeCard.visible();
    };
}

function changePhone(componentStore) {
    const {phoneCard, codeCard} = componentStore.all();

    return () => {
        codeCard.invisible();
        phoneCard.visible();
    };
}

function resendSmsCode(componentStore, timer) {
    const {
        phoneInput,
        codeInput,
        timerSpan,
        resendSmsCodeLink,
    } = componentStore.all();

    return async e => {
        e.preventDefault();
        codeInput.disable();
        resendSmsCodeLink.disable();

        const response = await authApi.getLoginAuthSmsCode(phoneInput.getValue());

        codeInput.enable();
        resendSmsCodeLink.enable();

        if (!response.ok) {
            if (response.statusCode === 429) {
                Toasts.addFromPreset(Toasts.PRESETS.WARNING, {
                    message: 'Слишком много попыток. Попробуйте позже',
                });
            } else {
                Toasts.addFromPreset(Toasts.PRESETS.WARNING);
            }

            return;
        }

        codeInput.setValue('');
        codeInput.error(null);
        timerSpan.show();
        resendSmsCodeLink.hide();
        timer.start(SMS_SEND_TIMEOUT_SECONDS);
    };
}

// TODO to validation
function codeChanged(componentStore, timer) {
    const {
        codeInput,
        timerSpan,
        resendSmsCodeLink,
        enterButton,
        authToEmailFromCode
    } = componentStore.all();

    let changed = false;

    return () => {
        if (codeInput.getValue().length === 4) {
            timer.isProcess
                ? timerSpan.hide()
                : resendSmsCodeLink.hide();

            enterButton.show();

            changed = true;
        } else if (changed) {
            timer.isProcess
                ? timerSpan.show()
                : resendSmsCodeLink.show();

            enterButton.hide();

            changed = false;
        }
    };
}

function enter(componentStore) {
    const {
        phoneInput,
        codeInput,
        enterButton,
    } = componentStore.all();

    return async () => {
        const phone = phoneInput.getValue();
        const code = codeInput.getValue();

        codeInput.disable();
        enterButton.disable();

        const response = await authApi.login(phone, code);

        codeInput.enable();
        enterButton.enable();

        if (response.statusCode === 422) {
            codeInput.error(response.body.errors.auth_code[0]);
        } else {
            window.location = response.body?.url ?? '/';
        }
    };
}

function timerOnChange(componentStore) {
    const timerValue = componentStore.getById('timerValue');

    return seconds => timerValue.textContent = seconds;
}

function timerOnEnd(componentStore) {
    const {
        timerSpan,
        resendSmsCodeLink,
        authToEmailFromCode,
    } = componentStore.all();

    return () => {
        if (timerSpan.classList.contains('hidden')) return;

        timerSpan.hide();
        resendSmsCodeLink.show();
        authToEmailFromCode.show();
    };
}

function updateAuthStateFactory(componentStore, fieldsGate) {
    const {
        fieldsForPhone,
        fieldsForEmail,
    } = componentStore.all();

    let state = 'email';

    function toggleType() {


        switch (state) {
            case 'phone':
                fieldsForEmail.forEach(field => {
                    field.hide();
                });
                fieldsForPhone.forEach(field => {
                    field.show();
                });
                break;
            case 'email':
                fieldsForEmail.forEach(field => {
                    field.show();
                });
                fieldsForPhone.forEach(field => {
                    field.hide();
                });
                break;
        }
    }

    function getState() {
        return state;
    }

    function setState(newState) {
        state = newState;
    }

    return {
        toggleType,
        setState,
        getState
    }
}


function handleChangeTypeAuth(type, {setState, toggleType}) {
    setState(type);
    toggleType();
}

function enterByEmailFactory(componentStore) {
    const {
        emailInput,
        inputPassword,
    } = componentStore.all();

    return async () => {
        const email = emailInput.getValue();
        const password = inputPassword.getValue();

        emailInput.disable();
        inputPassword.disable();

        const response = await authApi.loginByEmail(email, password);

        emailInput.enable();
        inputPassword.enable();

        if (!response.ok) {
            if (response.statusCode === 422) {
                emailInput.error((response.body.errors.email ?? [])[0] ?? null);
                inputPassword.error((response.body.errors.password ?? [])[0] ?? null);
            } else if (response.statusCode === 400) {
                Toasts.addFromPreset(Toasts.PRESETS.WARNING, {
                    message: response.body.message,
                });
            } else if (response.statusCode === 429) {
                // TODO maybe not necessary for email enter
                Toasts.addFromPreset(Toasts.PRESETS.WARNING, {
                    message: 'Слишком много попыток. Попробуйте позже',
                });
            } else {
                Toasts.addFromPreset(Toasts.PRESETS.WARNING);
            }

            return;
        }

        window.location = response.body?.url ?? '/';
    };
}

function restorePassword(componentStore) {
    const {
        emailInputRestore,
        restorePass,
        restorePassCard,
        restoreResponseCard,
        restoreEmail,
    } = componentStore.all();

    // TODO: отправка данных о восстановлении пароля
    return async () => {
        const email = emailInputRestore.getValue();

        emailInputRestore.disable();
        restorePass.disable();

        const response = await authApi.forgotPassword(email);

        if (!response.ok) {
            if (response.statusCode === 400) {
                Toasts.addFromPreset(Toasts.PRESETS.WARNING, {
                    message: 'Не найден пользователь с таким email.',//response.body.message,
                });
            } else {
                Toasts.addFromPreset(Toasts.PRESETS.WARNING);
            }
        } else {
            restorePassCard.invisible();
            restoreResponseCard.visible();
            restoreEmail.textContent = email;
        }

        emailInputRestore.enable();
        restorePass.enable();
    }
}

function index() {
    const {componentStore} = bootAuthLayout();
    const timer = new Timer();

    initMetrics(componentStore);
    const {gate: validationGate, emailRestoreValidator} = initValidation(componentStore);

    const {
        sendSmsCodeButton,
        changePhoneLink,
        resendSmsCodeLink,
        phoneInput,
        codeInput,
        enterButton,
        authToPhone,
        authToEmail,
        enterEmailButton,
        authToEmailFromCode,
        phoneCard,
        codeCard,
        restorePassBtn,
        restorePassCard,
        backToAuthBtn,
        restorePass,
        restoreResponseCard,
        backToRestorePassCard,
        emailInputRestore
    } = componentStore.all();

    const emailFields = componentStore.getTagsById('emailFields');

    const updateAuthState = updateAuthStateFactory(componentStore);
    const sendSmsCode_ = sendSmsCode(componentStore, timer);
    const enter_ = enter(componentStore);

    sendSmsCodeButton.on('click', sendSmsCode_);
    changePhoneLink.on('click', changePhone(componentStore));
    resendSmsCodeLink.on('click', resendSmsCode(componentStore, timer));
    codeInput.on('input', codeChanged(componentStore, timer));
    enterButton.on('click', enter_);

    emailFields.forEach((node) => {
        onEnter(node, enterByEmailFactory(componentStore), () => validationGate.isValid);
    })

    onEnter(phoneInput, sendSmsCode_);
    onEnter(codeInput, enter_);
    onEnter(emailInputRestore, restorePassword(componentStore), () => emailRestoreValidator.isValid)

    timer.on('change', timerOnChange(componentStore));
    timer.on('end', timerOnEnd(componentStore));

    authToPhone.on('click', () => handleChangeTypeAuth('phone', updateAuthState));
    authToEmail.on('click', () => handleChangeTypeAuth('email', updateAuthState));

    enterEmailButton.on('click', enterByEmailFactory(componentStore));

    authToEmailFromCode.on('click', () => {
        handleChangeTypeAuth('email', updateAuthState);
        phoneCard.visible();
        codeCard.invisible();
    });

    restorePassBtn.on('click', () => {
        phoneCard.invisible();
        restorePassCard.visible();
    });

    backToAuthBtn.on('click', () => {
        phoneCard.visible();
        restorePassCard.invisible();
    });

    restorePass.on('click', restorePassword(componentStore));
    backToRestorePassCard.on('click', () => {
        restorePassCard.visible();
        restoreResponseCard.invisible();
    });
}

window.addEventListener('DOMContentLoaded', index);
