import {Modal} from './Modal';
import { renderSpeaker } from '@scripts/helpers'

class SpeakerQuestionModal extends Modal {
    #templateSpeaker;
    #speakerItem;
    #speakerId;

    constructor(node, id) {
        super(node, id);

        this.#templateSpeaker = this.getSubItem('templateSpeaker');
        this.#speakerItem = this.getSubItem('speakerItem');

    }

    get templateSpeaker() {
        return this.#templateSpeaker;
    }

    get speakerItem() {
        return this.#speakerItem;
    }

    get speakerId() {
        return this.#speakerId;
    }

    renderSpeakerInfo(isAnonymous, speakerInfo) {
        const template = this.#templateSpeaker.content.cloneNode(true);
        this.#speakerItem.innerHtml = '';
        this.#speakerItem.innerText = '';

        const speaker = renderSpeaker(template, speakerInfo, isAnonymous);

        this.#speakerItem.appendChild(speaker);

        if (isAnonymous) {
            return;
        }

        this.#speakerId = speakerInfo.id;
        this.#speakerItem.dataset.speakerId = speakerInfo.id;
    }

}

export {SpeakerQuestionModal};
