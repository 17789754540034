class Component {
    #id;
    #node;
    #targets = new Map();
    #subItems;

    isLoadSubItems = true;
    defaultShowClass = 'hidden';
    defaultInvisibleClass = 'invisible';

    constructor(node, id) {
        this.#node = node;
        this.#id = id;

        this.isLoadSubItems && this.#loadSubItems();
    }

    methodNotImplementedGuard(method) {
        throw new Error(`Method "${method}" in component with id "${this.#id}" not implemented`);
    }

    subItemNotFoundGuard(subItem) {
        throw new Error(`Component with id "${this.#id}" has no sub item "${subItem}"`);
    }

    #loadSubItems() {
        const subItems = Array.from(this.#node.querySelectorAll('[data-component-subitem]'));

        this.#subItems = new Map();

        for (const subItem of subItems) {
            const subItemName = subItem.dataset.componentSubitem;

            this.#subItems.set(subItemName, subItem);
        }
    }

    get id() {
        return this.#id;
    }

    get node() {
        return this.#node;
    }

    get targets() {
        return this.#targets;
    }

    get defaultPropertyTarget() {
        return this.#node;
    }

    getPropertyTarget(property) {
        return this.#targets.get(property) || this.defaultPropertyTarget;
    }

    getProperty(name) {
        const target = this.getPropertyTarget(name);
        const property = target[name];

        if (typeof property === 'function') {
            return property.bind(target);
        }

        return property;
    }

    callProperty(property, ...args) {
        return this.getProperty(property)(...args);
    }

    get className() {
        return this.getProperty('className');
    }

    get classList() {
        return this.getProperty('classList');
    }

    get dataset() {
        return this.getProperty('dataset');
    }

    get textContent() {
        return this.getProperty('textContent');
    }

    set textContent(content) {
        this.getPropertyTarget('textContent').textContent = content;
    }

    addEventListener(type, listener, options = null) {
        return this.callProperty('addEventListener', type, listener, options);
    }

    removeEventListener(type, listener, options = null) {
        return this.callProperty('removeEventListener', type, listener, options);
    }

    dispatchEvent(event) {
        return this.callProperty('dispatchEvent', event);
    }

    getAttribute(attribute) {
        return this.callProperty('getAttribute', attribute);
    }

    setAttribute(attribute, value = '') {
        this.callProperty('setAttribute', attribute, value);

        return this;
    }

    removeAttribute(attribute) {
        this.callProperty('removeAttribute', attribute);

        return this;
    }

    getSubItem(subItem, replacer) {
        if (this.#subItems.has(subItem)) {
            return this.#subItems.get(subItem);
        }

        if (replacer !== undefined) {
            return replacer;
        }

        this.subItemNotFoundGuard(subItem);
    }

    show(showClass = null) {
        this.classList.remove(showClass || this.defaultShowClass);

        return this;
    }

    hide(showClass = null) {
        this.classList.add(showClass || this.defaultShowClass);

        return this;
    }

    #getInvisibleClass(invisibleClass) {
        return invisibleClass || this.defaultInvisibleClass;
    }

    visible(invisibleClass = null) {
        this.classList.remove(this.#getInvisibleClass(invisibleClass));

        return this;
    }

    invisible(invisibleClass = null) {
        this.classList.add(this.#getInvisibleClass(invisibleClass));

        return this;
    }

    toggleVisible(invisibleClass = null) {
        invisibleClass = this.#getInvisibleClass(invisibleClass);

        if (this.classList.contains(invisibleClass)) {
            this.visible(invisibleClass);
        } else {
            this.invisible(invisibleClass);
        }

        return this;
    }

    disable() {
        const target = this.getPropertyTarget('disabled');

        target.setAttribute('disabled', '');

        return true;
    }

    enable() {
        const target = this.getPropertyTarget('disabled');

        target.removeAttribute('disabled');

        return true;
    }

    on = this.addEventListener.bind(this);
    off = this.removeEventListener.bind(this);
}

export {Component};
