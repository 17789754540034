import {BaseClient} from './BaseClient';

class APIClient extends BaseClient {
    constructor() {
        const baseHeaders = {
            accept: 'application/json',
        };

        super({baseHeaders});
    }

    post(url, options = {}) {
        if (options.body && !options.bodyRequestFormat) {
            options.bodyRequestFormat = 'json';

            options.headers = {
                ...(options.headers || {}),
                'content-type': 'application/json',
            };
        }

        return super.post(url, options);
    }

    put(url, options = {}) {
        if (options.body && !options.bodyRequestFormat) {
            options.bodyRequestFormat = 'json';

            options.headers = {
                ...(options.headers || {}),
                'content-type': 'application/json',
            };
        }

        return super.put(url, options);
    }

    patch(url, options = {}) {
        if (options.body && !options.bodyRequestFormat) {
            options.bodyRequestFormat = 'json';

            options.headers = {
                ...(options.headers || {}),
                'content-type': 'application/json',
            };
        }

        return super.patch(url, options);
    }
}

export {APIClient};
