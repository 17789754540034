function stretchFactory(parent) {
    let oldHeight;

    return target => {
        const newHeight = target.clientHeight;

        if (oldHeight === newHeight) return;

        oldHeight = newHeight;
        parent.style.height = `${oldHeight}px`;
    };
}

function resizeObserverCallback(parent) {
    const stretch = stretchFactory(parent);

    return ([{target}]) => stretch(target);
}

function detectItemFactory(parent) {
    const resizeObserver = new ResizeObserver(resizeObserverCallback(parent));
    let item;

    return targets => {
        for (const target of targets) {
            if (!target.classList.contains('invisible')) {
                if (item) {
                    resizeObserver.unobserve(item);
                }

                resizeObserver.observe(target);

                item = target;

                break;
            }
        }
    };
}

function mutationObserverCallback(detectItem) {
    return records => detectItem(records.map(({target}) => target));
}

function bootAutoHeight() {
    const nodes = Array.from(document.querySelectorAll('[data-auto-height]'));

    if (nodes.length === 0) return;

    for (const node of nodes) {
        const id = node.dataset.autoHeight;

        const targetSelector = id
            ? `[data-auto-height-target=${id}]`
            : '[data-auto-height-target]';

        const targets = Array.from(node.querySelectorAll(targetSelector));

        if (targets.length === 0) continue;

        const detectItem = detectItemFactory(node);
        const mutationObserver = new MutationObserver(mutationObserverCallback(detectItem));

        for (const target of targets) {
            mutationObserver.observe(target, {
                attributes: true,
                attributeFilter: ['class'],
            });
        }

        detectItem(targets);
    }
}

export {bootAutoHeight};
