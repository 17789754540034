import {Factory} from './bodyTransformer';
import {Response as MyResponse} from './Response';

class BaseClient {
    #baseHeaders;
    #baseBodyRequestFormat;
    #baseBodyResponseFormat;

    constructor(
        {
            baseHeaders = {},
            baseBodyRequestFormat = 'json',
            baseBodyResponseFormat = 'json',
        } = {}
    ) {
        this.#baseHeaders = baseHeaders;
        this.#baseBodyRequestFormat = baseBodyRequestFormat;
        this.#baseBodyResponseFormat = baseBodyResponseFormat;
    }

    setBaseHeader(key, value) {
        this.#baseHeaders[key] = value;
    }

    removeBaseHeader(key) {
        delete this.#baseHeaders[key];
    }

    async sendRequest(
        url,
        {
            method = 'get',
            query = null,
            body = null,
            bodyRequestFormat,
            bodyResponseFormat,
            headers = {},
            options = {},
        } = {}
    ) {
        if (query) {
            const queryString = new URLSearchParams(query).toString();

            url += `?${queryString}`;
        }

        bodyRequestFormat = bodyRequestFormat || this.#baseBodyRequestFormat;
        bodyResponseFormat = bodyResponseFormat || this.#baseBodyResponseFormat;

        if (body && bodyRequestFormat) {
            body = Factory.make('request', bodyRequestFormat).transform(body);
        }

        headers = {...this.#baseHeaders, ...headers};
        options = {method, headers, body, ...options};

        const rawResponse = await fetch(url, options);
        const response = new MyResponse(rawResponse);

        await response.parseBody(bodyResponseFormat);

        return response;
    }

    post(url, options = {}) {
        return this.sendRequest(url, {
            method: 'post',
            ...options,
        });
    }

    get(url, options = {}) {
        return this.sendRequest(url, {
            method: 'get',
            ...options
        });
    }

    put(url, options = {}) {
        return this.sendRequest(url, {
            method: 'put',
            ...options
        });
    }

    patch(url, options = {}) {
        return this.sendRequest(url, {
            method: 'PATCH',
            ...options
        });
    }

    delete(url, options = {}) {
        return this.sendRequest(url, {
            method: 'delete',
            ...options
        });
    }
}

export {BaseClient};
