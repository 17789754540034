import {Overlay} from './Overlay';

class Modal extends Overlay {
    #card;
    #onCloseRequest = null;
    #prompt = null;
    #canClose = true;

    constructor(node, id) {
        super(node, id);

        this.on('click', this.#onOverlayClick.bind(this));

        //TODO переделать кастыль
        try {
            const closeButton = this.getSubItem('close');
            if(closeButton) {
                closeButton.addEventListener('click', this.close.bind(this));
            }
        } catch (e) {}


        this.#card = this.getSubItem('card');
    }

    setCanClose(canClose) {
        this.#canClose = canClose;

        return this;
    }

    #onOverlayClick(event) {
        if (!this.#card.contains(event.target) && this.#canClose) {
            this.close();
        }
    }

    onCloseRequest(callback) {
        this.#onCloseRequest = callback;

        return this;
    }

    close() {
        if ((!this.#onCloseRequest || this.#onCloseRequest() !== false) && this.#canClose) {
            this.invisible();
        }

        return this;
    }

    forceClose() {
        this.invisible();

        return this;
    }

    prompt(...args) {
        this.visible();

        return new Promise(res => this.#prompt = [res, args]);
    }

    getPromptArgs() {
        return this.#prompt[1];
    }

    answer(result) {
        this.#prompt[0](result);

        return this;
    }
}

export {Modal};
