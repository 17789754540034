import {Component} from '@scripts/components/Component';

class InputMultipleContainer extends Component {
    #clearNode;
    #moreBtn;
    #list;

    constructor(node, id) {
        super(node, id);

        const list = this.getSubItem('list', null);
        const moreBtn = this.getSubItem('moreBtn', null);



        if (moreBtn) {
            if (!moreBtn.onclick) {
                moreBtn.onclick = this.copyInput.bind(this)
            }
        }

        this.#clearNode = list.querySelector('[data-component]').cloneNode(true);
        this.#clearNode.classList.remove('hidden');
        this.#clearNode.querySelector('input').value = '';
        this.#clearNode.querySelector('input').removeAttribute('disabled');
        delete this.#clearNode.dataset.filled;

        this.#list = list;
        this.#moreBtn = moreBtn;
    }

    copyInput() {

        const elClone = this.#clearNode.cloneNode(true);
        const type = this.#clearNode.dataset.component;
        const collection = this.#clearNode.dataset.componentCollection;
        this.#list.appendChild(elClone);

        document.dispatchEvent(new CustomEvent('registrCollection', {
            detail: {
                type,
                collection,
                componentNode: elClone,
            },
        }));

        this.dispatchEvent(new CustomEvent('copying'));
    }
}

export {InputMultipleContainer};
