import {Component} from './Component';
import {createSvg} from '@scripts/helpers/createSvg';

class Toasts extends Component {
    constructor(node, id) {
        super(node, id);

        const toasts = node.children;

        for (const toast of toasts) {
            toast.dataset.toastDefered === undefined && this.prepare(toast);
        }
    }

    prepare(toast) {
        toast.classList.remove('toast--prepared');

        return this;
    }

    static #renderToast(message, {
        icon = null,
        style = 'regular',
    } = {}) {
        const containerDiv = document.createElement('div');
        const messageDiv = document.createElement('p');

        if (icon) {
            const iconSvg = document.createElement('svg');

            iconSvg.className = 'toast__icon';
            createSvg(iconSvg, icon);

            containerDiv.appendChild(iconSvg);
        }

        if (style !== 'regular') {
            containerDiv.classList.add(`toast--${style}`);
        }

        messageDiv.className = 'toast__text';
        messageDiv.textContent = message;

        containerDiv.classList.add('toast', 'toast--prepared');
        containerDiv.appendChild(messageDiv);

        containerDiv.innerHTML += '';

        return containerDiv;
    }

    destroy(toast) {
        this.callProperty('removeChild', toast);

        return this;
    }

    remove(toast) {
        toast.classList.add('toast--removed');
        // TODO 150 => config
        setTimeout(() => this.destroy(toast), 150);

        return this;
    }

    add(message, options) {
        const toast = Toasts.#renderToast(message, options);

        this.callProperty('appendChild', toast);
        setTimeout(() => this.prepare(toast));
        // TODO 3000 => config
        setTimeout(() => this.remove(toast), 3000);

        return this;
    }
}

export {Toasts};
