import {Store} from '@scripts/components';

import {bootMetrics} from './bootMetrics';
import {bootToasts} from './bootToasts';

function bootTemplate() {
    const componentStore = new Store().discover();

    bootMetrics();
    bootToasts(componentStore);

    return {componentStore};
}

export {bootTemplate};
