class Timer {
    #counter = 0;
    #isProcess = false;
    #lastTime = null;
    #callbacks = {};
    #delay;

    constructor(delay = 100) {
        this.#delay = delay;
    }

    get isProcess() {
        return this.#isProcess;
    }

    on(event, callback) {
        if (!(event in this.#callbacks)) {
            this.#callbacks[event] = [];
        }

        this.#callbacks[event].push(callback);

        return this;
    }

    dispatch(event, ...args) {
        const callbacks = this.#callbacks[event];

        if (!callbacks) {
            return this;
        }

        for (const callback of callbacks) {
            callback(...args);
        }

        return this;
    }

    process() {
        const now = Date.now();

        if (!this.#lastTime) {
            this.dispatch('change', this.formatSeconds(this.#counter));
            this.#lastTime = now;
        } else if (now - this.#lastTime >= 1000) {
            this.#counter--;
            this.#lastTime = now;

            this.dispatch('change', this.formatSeconds(this.#counter));
        }

        if (this.#counter === 0) {
            this.stop();
            this.dispatch('end');
        } else if (this.isProcess) {
            setTimeout(this.process.bind(this), this.#delay);
        }
    }

    start(seconds) {
        if (!this.#isProcess) {
            this.#counter = seconds;
            this.#isProcess = true;
            this.#lastTime = null;
            this.process();
        }

        return this;
    }

    stop() {
        this.#isProcess = false;

        return this;
    }

    formatSeconds(seconds) {
        let minutes = Math.trunc(seconds / 60);
        let leftSeconds = seconds % 60;

        minutes = minutes.toString().padStart(2, '0');
        leftSeconds = leftSeconds.toString().padStart(2, '0');

        return `${minutes}:${leftSeconds}`;
    }
}

export {Timer};
