import {Validator} from '@scripts/validation/Validator';
import {Requests} from '@scripts/http/api';

const requestsApi = new Requests();

function initValidation(componentStore) {
    const {
        beModeratorSelect,
        beModeratorSuccessButton,
    } = componentStore.all();

    new Validator()
        .required()
        .validateCallback(value => value.length)
        .formField(beModeratorSelect)
        .getValue(() => beModeratorSelect.getValue())
        .withoutMessage()
        .compile()
        .onValid(() => beModeratorSuccessButton.enable())
        .onInvalid(() => beModeratorSuccessButton.disable());
}

function beModerator(componentStore) {
    const {
        beModeratorSelect,
        beModeratorSuccessButton,
        beModeratorCancelButton,
    } = componentStore.all();

    return async () => {
        const sectionIds = beModeratorSelect.getValue().map(Number);

        beModeratorSelect.disable();
        beModeratorSuccessButton.disable();
        beModeratorCancelButton.disable();

        // TODO critical errors
        await requestsApi.beModerator(sectionIds);

        window.location.href = route('personal.requests.index');
    };
}

function bootSideMenu(componentStore) {
    const {
        beModeratorLinks = [],
        beModeratorModal,
        beModeratorSuccessButton,
        beModeratorCancelButton,
        rightMenu,
    } = componentStore.all();

    initValidation(componentStore);

    if (beModeratorLinks.length === 0) return;

    for (const beModeratorLink of beModeratorLinks) {
        beModeratorLink.on('click', () => {
            beModeratorModal.visible();
            rightMenu.removeAttribute('data-opened');
        });
    }

    beModeratorSuccessButton.on('click', beModerator(componentStore));
    beModeratorCancelButton.on('click', () => beModeratorModal.invisible());
}

export {bootSideMenu};
