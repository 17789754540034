import {Input} from '@scripts/components/Input';

class InputDelete extends Input {

    #deleteBtn;

    constructor(node, id) {
        super(node, id);

        const deleteBtn = this.getSubItem('deleteBtn', null);

        if (deleteBtn) {
            deleteBtn.addEventListener('click', this.#onDelete.bind(this));
        }

        this.#deleteBtn = deleteBtn
    }

    #onDelete(event) {
        event.stopPropagation();
        this.disable();
        this.hide();
        this.node.remove();
    }
}

export {InputDelete};
