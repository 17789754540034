import {FormField} from './FormField';

class Input extends FormField {
    get maskedValue() {
        return this.field.value;
    }

    get rawValue() {
        return this.field.inputmask?.unmaskedvalue() ?? this.maskedValue;
    }

    get inputmask() {
        return this.field.inputmask;
    }

    error(message) {
        super.error(message);

        if (message) {
            this.classList.add('input--danger');
        } else {
            this.classList.remove('input--danger');
        }

        return this;
    }
}

export {Input};
