import {FormField} from './FormField';

class Checkbox extends FormField {
    constructor(node, id) {
        super(node, id);

        this.targets.set('disabled', node);
    }

    get rawValue() {
        return this.getPropertyTarget('value').checked;
    }

    setValue(value) {
        this.getPropertyTarget('value').checked = value;

        return this;
    }
}

export {Checkbox};
