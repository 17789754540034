function renderSpeaker(template, speakerInfo, isAnonymous) {

    if (isAnonymous) {
        template.querySelector('.speaker-info__name').innerText = 'Анонимно'
    } else {
        if (speakerInfo.avatar) {
            template.querySelector('.speaker-info__img-plug').classList.add('hidden');
            const avatar = document.createElement('img');
            avatar.setAttribute('src', speakerInfo.avatar);
            template.querySelector('.speaker-info__img').appendChild(avatar);
        }
        template.querySelector('.speaker-info__name').innerText = speakerInfo.short_name ? speakerInfo.short_name : speakerInfo.shortName;
        template.querySelector('.speaker-info__company').innerText = speakerInfo.company;
        template.querySelector('.speaker-info__description').innerText = speakerInfo.position;
    }

    return template;
}

export {renderSpeaker};
