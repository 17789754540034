import { getCookie, setCookie } from '@scripts/helpers';
import {Modal} from './Modal';

class TelegramModal extends Modal {
    #btn;

    constructor(node, id) {
        super(node, id);

        this.#btn = this.getSubItem('telegramModalBtn');
        this.#init();
    }

    #showModal() {
        if (getCookie('telegram-hide')) {
            return;
        }

        this.visible();
        setCookie('telegram-hide', 1, {
            expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        });
    }

    #init() {
        this.#showModal();

        this.#btn.addEventListener('click', () => this.invisible() );
    }
}

export {TelegramModal};
