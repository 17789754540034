import {Component} from './Component';

class Overlay extends Component {
    visible() {
        super.visible();

        document.body.classList.add('scroll-lock');
    }

    invisible() {
        super.invisible();

        document.body.classList.remove('scroll-lock');
    }
}

export {Overlay};
