import {FormField} from './FormField';
import {EventEmitter} from '@scripts/helpers';
import {Store} from '@scripts/components';

class File extends FormField {
    #file = null;
    #eventEmitter;
    #inputNode;
    #nameNode;
    #sizeNode;
    #downloadNode;
    #progressbarNode;
    #nameModifier = null;
    #sizeModifier;
    #onDeleteRequest = null;
    #moreBtn;
    #isMultiple;
    #containerNode;
    #clearNode;

    constructor(node, id) {
        super(node, id);

        const container = this.getSubItem('container');
        const inputNode = this.getSubItem('input');
        const nameNode = this.getSubItem('name');
        const sizeNode = this.getSubItem('size', null);
        const downloadNode = this.getSubItem('download', null);
        const deleteNode = this.getSubItem('delete', null);
        const progressbarNode = this.getSubItem('progressbar', null);

        container.addEventListener('click', this.#proxyClick.bind(this));
        container.addEventListener('dragover', File.#onDragOver);
        container.addEventListener('drop', this.#onDrop.bind(this));
        inputNode.addEventListener('input', () => this.#handleFiles(inputNode.files));

        if (deleteNode) {
            deleteNode.addEventListener('click', this.#onDelete.bind(this));
        }

        this.#eventEmitter = new EventEmitter();
        this.#inputNode = inputNode;
        this.#nameNode = nameNode;
        this.#sizeNode = sizeNode;
        this.#downloadNode = downloadNode;
        this.#progressbarNode = progressbarNode;
        this.#sizeModifier = node.dataset.sizeModifier || null;
        this.#containerNode = container;
    }

    static #onDragOver(event) {
        event.preventDefault();
    }

    get defaultPropertyTarget() {
        return this.node;
    }

    get rawValue() {
        return this.#file;
    }

    addEventListener(type, listener, options = null) {
        this.#eventEmitter.on(type, listener);

        return this;
    }

    removeEventListener(type, listener, options = null) {
        this.#eventEmitter.off(type, listener);

        return this;
    }

    setNameModifier(nameModifier) {
        this.#nameModifier = nameModifier;

        return this;
    }

    setSizeModifier(sizeModifier) {
        this.#sizeModifier = sizeModifier;

        return this;
    }

    onDeleteRequest(callback) {
        this.#onDeleteRequest = callback;

        return this;
    }

    isFilled() {
        return 'filled' in this.dataset;
    }

    #proxyClick() {
        if (this.isFilled()) return;

        this.#inputNode.click();
    }

    #onDrop(event) {
        const {dataTransfer: {files}} = event;

        event.preventDefault();
        this.#handleFiles(files);

        return false;
    }

    filled(filled = true) {
        if (filled) {
            this.setAttribute('data-filled', '');
        } else {
            this.removeAttribute('data-filled');
        }

        return this;
    }

    setName(name) {
        if (this.#nameModifier) {
            name = this.#nameModifier(name);
        }

        this.#nameNode.textContent = name;

        return this;
    }

    setRawSize(size) {
        this.#sizeNode.textContent = size;

        return this;
    }

    setSize(size) {
        let units = 'б';

        //todo вынести делители в константы
        if (this.#sizeModifier) {
            if (typeof this.#sizeModifier === 'function') {
                [size, units] = this.#sizeModifier(size);
            } else if (this.#sizeModifier === 'kb') {
                size = Math.round(size / 1024);
                units = 'kb';
            } else if (this.#sizeModifier === 'mb') {
                size = Math.round(size / 1048576);
                units = 'mb';
            }
        }

        this.setRawSize(`${size} ${units}`);

        return this;
    }

    setDownload(href, name) {
        this.#downloadNode.href = href;
        this.#downloadNode.download = name;

        return this;
    }

    setValue(file) {
        const fileName = file.name;

        this.#file = file;

        this.filled();
        this.setName(fileName);

        if (this.#sizeNode) {
            this.setSize(file.size);
        }

        if (this.#downloadNode) {
            const href = URL.createObjectURL(file);

            this.setDownload(href, fileName);
        }

        return this;
    }

    setProgressbar(percents) {
        if (typeof percents !== 'number') {
            this.#progressbarNode.parentNode.classList.add('hidden');
        } else {
            this.#progressbarNode.parentNode.classList.remove('hidden');
        }

        this.#progressbarNode.style.width = `${percents}%`;

        return this;
    }

    clear() {
        this.#file = null;
        this.#inputNode.value = '';

        setTimeout(() => this.filled(false));

        return this;
    }

    #handleFiles([file]) {
        if (!file) return;

        this.setValue(file);
        this.#eventEmitter.emit('input', file);

        if (this.#isMultiple) {
            this.#moreBtn.classList.remove('hidden');
        }
    }

    async #onDelete(event) {
        if (this.#onDeleteRequest && (await this.#onDeleteRequest(event) === false)) return;

        this.clear();
        this.#eventEmitter.emit('delete');
        this.#eventEmitter.emit('input', null);
    }

}

export {File};
