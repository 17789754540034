import {APIClient} from '../APIClient';
import {csrf} from '@scripts/helpers';

class Requests extends APIClient {
    beModerator(sectionIds) {
        return this.post(route('personal.moderation.request'), {
            body: {
                section_ids: sectionIds,
            },
            headers: {
                'x-csrf-token': csrf(),
            },
        });
    }

    cancel(id) {
        return this.post(route('personal.requests.cancel'), {
            body: {id},
            headers: {
                'x-csrf-token': csrf(),
            },
        });
    }

    beSpeaker(topic, abstracts, presentation, participation, section_id) {
        const body = {
            topic,
            theses: abstracts,
            section_id,
        };

        if (presentation) {
            body.presentation = presentation;
        }

        if (participation) {
            body.participation = participation;
        }

        return this.post(route('request.speaker'), {
            body,
            headers: {
                'x-csrf-token': csrf(),
            },
            bodyRequestFormat: 'form',
        });
    }

    nextForumParticipation() {
        return this.post(route('api.nextForumParticipation.store'));
    }

    // TODO remove update speaker request
    // speakerUpdate(requestId, topic, abstracts, presentation, isDeletePresentation) {
    //     const body = {
    //         topic,
    //         theses: abstracts,
    //     };
    //
    //     if (presentation) {
    //         body.presentation = presentation;
    //     }
    //
    //     if (isDeletePresentation) {
    //         body.delete_presentation = 'true';
    //     }
    //
    //     return this.post(route('personal.requests.update', requestId), {
    //         body: {
    //             ...body,
    //             '_method': 'put',
    //         },
    //         headers: {
    //             'x-csrf-token': csrf(),
    //         },
    //         bodyRequestFormat: 'form',
    //     });
    // }

    // TODO remove beCospeaker
    // beCospeaker(eventId) {
    //     return this.post(route('request.cospeaker', {'presentation': eventId}), {
    //         headers: {
    //             'x-csrf-token': csrf(),
    //         }
    //     })
    // }
}

export {Requests};
