class EventEmitter {
    #listeners = new Map();

    get listeners() {
        return this.#listeners;
    }

    on(event, listener) {
        if (!this.#listeners.has(event)) {
            this.#listeners.set(event, []);
        }

        this.#listeners.get(event).push(listener);

        return this;
    }

    off(event, listener) {
        const listeners = this.#listeners.get(event);

        if (!listeners) return;

        const filtered = listeners.filter(listener_ => listener !== listener_);

        if (filtered.length === 0) {
            this.#listeners.delete(event);
        } else {
            this.#listeners.set(event, filtered);
        }

        return this;
    }

    emit(event, ...args) {
        const listeners = this.#listeners.get(event);

        if (!listeners) return;

        for (const listener of listeners) {
            listener(...args);
        }

        return this;
    }
}

export {EventEmitter};
