import {Transformer} from '../Transformer';

class FormTransformer extends Transformer {

    transform(data) {
        const dataEntries = Object.entries(data);
        const formData = new FormData();

        for (const [key, value] of dataEntries) {
            if (Array.isArray(value)) {
                const valueEntries = Object.entries(value);

                if (valueEntries.length > 0) {
                    for (const [i, item] of valueEntries) {
                        this.appendToFormData(item, `${key}[${i}]`, formData);
                    }
                } else {
                    formData.set(key, '');
                }

            } else {
                formData.set(key, value);
            }
        }

        return formData;
    }

    appendToFormData(data, name, formData) {
        if (typeof data === 'object') {
            const valueEntries = Object.entries(data);
            if (valueEntries.length > 0) {
                for (const [i, item] of valueEntries) {
                    if (typeof item === 'object') {
                        return this.appendToFormData(item, `${name}[${i}]`, formData);
                    } else {
                        formData.append(`${name}[${i}]`, item);
                    }
                }
            } else {
                formData.append(name, '');
            }
        } else {
            formData.append(name, data);
        }
    }
}

export {FormTransformer};
