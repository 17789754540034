import Swiper from 'swiper';

import {Component} from './Component';
import {EventEmitter} from '@scripts/helpers';

class SwiperTabsLinks extends Component {
    #eventEmitter = new EventEmitter();
    #swiper;
    #tabs;
    #tabsIndexes;
    #keys;
    #shadowLeft;
    #shadowRight;
    #controlLeft;
    #controlRight;
    #selected = null;

    constructor(node, id) {
        super(node, id);

        const {tabs, tabsIndexes, keys} = Array
            .from(node.querySelectorAll('[data-tabs-key]'))
            .reduce((res, tab, i) => {
                const key = tab.dataset.tabsKey;

                tab.addEventListener('click', () => this.select(key));

                res.tabs[key] = tab;
                res.tabsIndexes[key] = i;

                res.keys.push(key);

                return res;
            }, {
                tabs: {},
                tabsIndexes: {},
                keys: [],
            });

        const active = node.dataset.active;
        const spaceBetween = node.getAttribute('data-space-between');

        this.#swiper = new Swiper(node, {
            slidesPerView: 'auto',
            spaceBetween: +spaceBetween,
        });

        this.#tabs = tabs;
        this.#tabsIndexes = tabsIndexes;
        this.#keys = keys;
        this.#shadowLeft = this.getSubItem('shadowLeft', null);
        this.#shadowRight = this.getSubItem('shadowRight', null);
        this.#controlLeft = this.getSubItem('controlLeft', null);
        this.#controlRight = this.getSubItem('controlRight', null);

        this.#swiper.on('progress', this.handleProgress());

        if (active) {
            this.select(active);
        }

        if (this.#controlLeft) {
            this.#controlLeft.addEventListener('click', () => this.#prevNext(true));
        }

        if (this.#controlRight) {
            this.#controlRight.addEventListener('click', () => this.#prevNext(false));
        }
    }

    #prevNext(isPrev) {
        let nextIndex;

        if (!this.#selected) {
            nextIndex = 0;
        } else {
            const currentIndex = this.#keys.indexOf(this.#selected);

            if (isPrev) {
                nextIndex = currentIndex - 1;
            } else {
                nextIndex = currentIndex + 1;
            }
        }

        const nextKey = this.#keys[nextIndex] ?? null;

        if (nextKey) {
            this.select(nextKey);
        }
    }

    select(key) {
        const tab = this.#tabs[key];

        if (this.#selected) {
            this.#tabs[this.#selected].classList.add('btn--outlined');
        }

        tab.classList.remove('btn--outlined');
        this.#swiper.slideTo(this.#tabsIndexes[key]);
        this.emit('change', key, this.#selected);

        this.#selected = key;

        return this;
    }

    handleProgress() {
        let isLeftShadowVisible = false;
        let isRightShadowVisible = false;
        let isLeftControlVisible = false;
        let isRightControlVisible = false;

        return (_, progress) => {
            if (progress >= 0.1) {
                if (this.#shadowLeft && !isLeftShadowVisible) {
                    isLeftShadowVisible = true;

                    this.#shadowLeft.classList.remove('invisible');
                }

                if (this.#controlLeft && !isLeftControlVisible) {
                    isLeftControlVisible = true;

                    this.#controlLeft.classList.remove('invisible');
                }
            } else if (progress < 0.1) {
                if (this.#shadowLeft && isLeftShadowVisible) {
                    isLeftShadowVisible = false;

                    this.#shadowLeft.classList.add('invisible');
                }

                if (this.#controlLeft && isLeftControlVisible) {
                    isLeftControlVisible = false;

                    this.#controlLeft.classList.add('invisible');
                }
            }

            if (this.#swiper.isEnd) {
                if (this.#shadowRight && isRightShadowVisible) {
                    isRightShadowVisible = false;

                    this.#shadowRight.classList.add('invisible');
                }

                if (this.#controlRight && isRightControlVisible) {
                    isRightControlVisible = false;

                    this.#controlRight.classList.add('invisible');
                }
            } else {
                if (this.#shadowRight && !isRightShadowVisible) {
                    isRightShadowVisible = true;

                    this.#shadowRight.classList.remove('invisible');
                }

                if (this.#controlRight && !isRightControlVisible) {
                    isRightControlVisible = true;

                    this.#controlRight.classList.remove('invisible');
                }
            }
        };
    }

    on = this.#eventEmitter.on.bind(this.#eventEmitter);
    off = this.#eventEmitter.off.bind(this.#eventEmitter);
    emit = this.#eventEmitter.emit.bind(this.#eventEmitter);
}

export {SwiperTabsLinks};
