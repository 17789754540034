import {Input} from './Input';

class InputPassword extends Input {
    #passControll;

    constructor(node, id) {
        super(node, id);

        this.#passControll = this.getSubItem('passControll', null);

        this.init();
    }

    init() {
        this.#passControll.addEventListener('click', () => {
            const fieldType = this.field.getAttribute('type');
            switch (fieldType) {
                case 'password':
                    this.field.setAttribute('type', 'text');
                    break
                case 'text':
                    this.field.setAttribute('type', 'password');
                    break;
            }
            this.#passControll.classList.toggle('pass-controll--open')
        });
    }
}

export {InputPassword};
